import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { Container, Row, Col, Fade } from "reactstrap"
import Swal from "sweetalert"

//Import Images
import error from "../../assets/images/Checklist.jpg"

const Pages500 = () => {
  const navigate = useNavigate()

  // confirmDelete()
  return (
    <React.Fragment>
      <Fade>
        <div className="main-body-inquiry">
          <div className="main-inquiry">
            <div className="text-center">
              <h1 className="text-thankyou">Thank you !</h1>
              <p className="fs-4">
                Success! We've received your submission Thank you for completing
                this form.
              </p>
              <p className="fs-4">you should receive a confirmation soon.</p>
              <button className="go-home" onClick={() => navigate("/login")}>
                go home
              </button>
            </div>
          </div>
          <div className="container">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-2  border-top-i">
              <div className="col-md-6 d-flex align-items-center">
                <p className="text-dark no-wrap fs-4">
                  © Copyright 2023 WhiteCore Technology LLP
                </p>
              </div>
              <div className="col-md-6 d-flex text-sm-end">
                <p className="text-dark no-wrap ms-auto fs-4">
                  <i className="fa fa-envelope pe-1"></i>
                  info@whitecoretechnology.com
                </p>
              </div>
            </footer>
          </div>
        </div>
      </Fade>
    </React.Fragment>
  )
}

export default Pages500
