import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import Profile from "../pages/UserProfile/Profile"

//Master
import User from "pages/Master/User/User"
import Adduser from "pages/Master/User/Adduser"
import Timeslote from "pages/Master/timeslote/Timeslote"
import Size from "pages/Master/size/Size"
import Inquiries from "pages/Master/inquries/Inquiries"
import Color from "pages/Master/color/Color"
import Categories from "pages/Master/Categories/Categories"
import Plan from "pages/Master/Plan/Plan"

import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Products
import Products from "../pages/Master/Inventory/Products/Products"

// Transactions
import Booking from "pages/Transiction/Booking"
import CreateBooking from "pages/Transiction/CreateBooking"
import CartListTable from "pages/Transiction/CartListTable"

// Reports
import RBooking from "pages/Reports/Booking/RBooking"
import Delivery from "pages/Reports/Delivery/Delivery"
import Feedback from "pages/Reports/Feedback"
import Return from "pages/Reports/Return/Return"
import Sales from "pages/Reports/Sales/Sales"

// setting
import Setting from "pages/Setting/Setting"
import Demo from "pages/Demo"
import Avaiblity from "pages/Avaiblity/Avaiblity"
import UpadateUser from "pages/Master/User/UpadateUser"

//invoice
import Invoice from "pages/Invoice/Invoice"
// Subscriptions
import Subscriptions from "pages/Subcription/Subscription"

import { routes } from "./path"
import ViewBooking from "pages/Transiction/BookingView"
import ImportProduct from "pages/ImportProduct/ImportProduct"
import ProductHistory from "pages/ProductHistory/ProductHistory"
import Salesman from "pages/Reports/Salesman/Salesman"
import AllPlans from "pages/AllPlans"
import ProductVerificationList from "pages/Master/Inventory/ProductVerification/ProductVerificationList"
import UploadImages from "pages/Master/Inventory/ProductVerification/UploadImages"
import AdvertisementList from "pages/Advertisement/AdvertisementList"
import PopularCategoriesList from "pages/Master/PopularCategories/PopularCategoriesList"
import ProductVerificationUpdate from "pages/Master/Inventory/ProductVerification/ProductVerificationUpdate"
import ProductCreateUpdate from "pages/Master/Inventory/Products/ProductCreateUpdate"
import AppVersion from "pages/Master/AppVersion/AppVersion"
import DBBackup from "pages/DBBackup/DBBackup"
import Notification from "pages/Master/Notifrication/Notification"

const authProtectedRoutes = [
  { path: routes.dashboard, component: <Dashboard /> },

  // Master
  { path: routes.userList, component: <User /> },
  { path: routes.userCreate, component: <Adduser /> },
  { path: routes.userUpdate, component: <UpadateUser /> },
  { path: routes.popularCategories, component: <PopularCategoriesList /> },
  { path: routes.inquiries, component: <Inquiries /> },
  { path: routes.categories, component: <Categories /> },
  { path: routes.size, component: <Size /> },
  { path: routes.color, component: <Color /> },
  { path: routes.timeslot, component: <Timeslote /> },
  { path: routes.plan, component: <Plan /> },
  { path: routes.available, component: <Avaiblity /> },
  { path: routes.appVersion, component: <AppVersion /> },
  { path: routes.notification, component: <Notification /> },
  { path: routes.demo, component: <Demo /> },

  // Product
  { path: routes.importProduct, component: <ImportProduct /> },
  { path: routes.productHistory, component: <ProductHistory /> },

  // Transaction

  { path: routes.bookingList, component: <Booking /> },
  { path: routes.createbooking, component: <CreateBooking /> },
  { path: routes.updatebooking, component: <CreateBooking /> },
  { path: routes.viewBooking, component: <InvoiceDetail /> },
  { path: routes.cartlist, component: <CartListTable /> },

  // Inventory
  { path: routes.productList, component: <Products /> },
  { path: routes.productCreate, component: <ProductCreateUpdate /> },
  { path: routes.productUpdate, component: <ProductCreateUpdate /> },
  { path: routes.productVerification, component: <ProductVerificationList /> },
  { path: routes.productVerificationUpdate, component: <ProductVerificationUpdate /> },
  { path: routes.uploadImage, component: <UploadImages /> },

  // Advertisement
  { path: routes.advertisement, component: <AdvertisementList /> },

  // Reports
  { path: routes.reportBooking, component: <RBooking /> },
  { path: routes.delivery, component: <Delivery /> },
  { path: routes.return, component: <Return /> },
  { path: routes.feedback, component: <Feedback /> },
  { path: routes.salesman, component: <Salesman /> },
  { path: routes.salesReport, component: <Sales /> },

  // Settings
  { path: routes.setting, component: <Setting /> },

  // db-backup
  { path: routes.dbBackup, component: <DBBackup /> },

  { path: routes.invoice, component: <Invoice /> },
  // Subscriptions
  { path: routes.subscription, component: <Subscriptions /> },

  // Profile
  { path: routes.profile, component: <Profile /> },

  //profile
  // { path: "/profile", component: <UserProfile /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  // { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/inquiry", component: <Register /> },
  { path: "/plans", component: <AllPlans /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/submission-inquiry", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }