import TableList from "components/Common/TableList"
import React from "react"
import { useMemo } from "react"

function CommonValidationTable({ category }) {
  const columns = useMemo(
    () => [
      {
        Header: "SrNo",
        accessor: "SrNo",
        Cell: ({ row }) => {
          return (
            <div
              style={{ color: row.original.ErrorMessage != "N/A" ? "red" : "" }}
            >
              {row.original.SrNo}
            </div>
          )
        },
      },
      {
        Header: "Name",
        accessor: "Name",
        Cell: ({ row }) => {
          return (
            <div
              style={{ color: row.original.ErrorMessage != "N/A" ? "red" : "" }}
            >
              {row.original.Name}
            </div>
          )
        },
      },
      {
        Header: "Code",
        accessor: "Code",
        Cell: ({ row }) => {
          return (
            <div
              style={{ color: row.original.ErrorMessage != "N/A" ? "red" : "" }}
            >
              {row.original.Code}
            </div>
          )
        },
      },
      {
        Header: "Error Message",
        accessor: "ErrorMessage",
        Cell: ({ row }) => {
          return (
            <div
              style={{ color: row.original.ErrorMessage != "N/A" ? "red" : "" }}
            >
              {row.original.ErrorMessage}
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <div>
      <TableList
        columns={columns}
        data={category}
        isGlobalFilter={true}
        isCategoryList={true}
        customPageSize={category.length}
        className="custom-header-css"
      />
    </div>
  )
}

export default CommonValidationTable
