import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import * as Yup from "yup"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import AppVersionTable from "./AppVersionTable"

function AppVersion() {
  document.title = `AppVersion | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { getApi, postApi, putApi } = useApi()
  // useStates
  const [modal, setModal] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)

  // version list api
  const appVersionApi = () => {
    setIsLoading(true)
    getApi("api/Setting/version-list", '').then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.versionList)
        setTotalRecords(data.totalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // version list api useEffect
  useEffect(() => {
    appVersionApi()
  }, [])

  // toggle handlers
  const toggle = () => {
    setModal(!modal)
    validation.handleReset()
  }

  // Add version handler
  const handleAddVersion = () => {
    toggle()
  }

  // AppVersion Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      version: "",
      isLive: false,
      isInReview: false,
    },
    validationSchema: Yup.object({
      version: Yup.string().required("The version is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (values) {
        postApi("api/Setting/version-add", {
          Version: values.version,
          IsLive: values.isLive,
          IsInReview: values.isInReview,
        }).then(({ data, status }) => {
          setformSumitLoading(false)
          if (status == 200) {
            toast.success("successfully add", {
              style: {
                fontSize: "15px",
              },
            })
            toggle()
            appVersionApi()
            validation.handleReset()
          } else {
            toast.error(JSON.parse(data)?.Message, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
    },
  })

  // version live handler
  const handleSwitch = (id, IsLive, e) => {
    if (id) {
      if (IsLive) {
        $("#isLive_" + id + "").prop("checked", IsLive)
        toast.error("At least one version must remain live", {
          style: {
            fontSize: "15px",
          },
        })
      }
      else {
        putApi(`api/Setting/version-active-inactive?id=${id}`, '').then(({ data, status }) => {
          e.preventDefault()
          if (status === 200) {
            appVersionApi()
            toast.success("version sucessfully changed", {
              style: {
                fontSize: "15px",
              },
            })
          } else {
            $("#isLive_" + id + "").prop("checked", IsLive)
            ErrorModel.errormodel({
              title: "Verify Version",
              text: data,
            })
          }
        })
      }
    }
  }

  // isInReview status change handler
  const handleSwitchIsInReview = (id, isInReview, e) => {
    if (id) {
      putApi(`api/Setting/change-review-status?id=${id}`, '').then(({ data, status }) => {
        e.preventDefault()
        if (status === 200) {
          appVersionApi()
          toast.success("sucessfully changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          $("#isInReview_" + id + "").prop("checked", isInReview)
          ErrorModel.errormodel({
            title: "Verify InReview",
            text: data,
          })
        }
      })
    }
  }

  // version table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Version",
        accessor: "version",
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Live
          </div>
        ),
        width: "6%",
        accessor: "isLive",
        disableFilters: true,
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`isLive_${row.original.id}`}
              defaultChecked={row.original.isLive}
              onClick={e => {
                handleSwitch(row.original.id, row.original.isLive, e)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Review
          </div>
        ),
        width: "6%",
        accessor: "isInReview",
        disableFilters: true,
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`isInReview_${row.original.id}`}
              defaultChecked={row.original.isInReview}
              onClick={e => {
                handleSwitchIsInReview(row.original.id, row.original.isInReview, e)
              }}
            />
          </FormGroup>
        ),
      },
    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <AppVersionTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isAddVersionList={true}
              handleAddVersion={handleAddVersion}
              customPageSize={10}
              className="custom-header-css"
              isLoading={isLoading}
              TotalRecords={totalRecords}
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              style={{ width: "380px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {"Add Version"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={5}>
                        <FormGroup className="mb-2">
                          <Label htmlFor="validationCustom03" className="required">
                            Version
                          </Label>
                          <Input
                            name="version"
                            placeholder="version"
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.version || ""}
                            invalid={
                              validation.touched.version && validation.errors.version
                                ? true
                                : false
                            }
                          />
                          {validation.touched.version && validation.errors.version ? (
                            <FormFeedback type="invalid">
                              {validation.errors.version}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={3} style={{ marginTop: "25px" }}>
                        <FormGroup check>
                          <Input
                            name='isLive'
                            id="checkbox2"
                            type="checkbox"
                            defaultChecked={validation.values.isLive}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.isLive}
                          />
                          <Label for="checkbox2" style={{ marginTop: "1px" }} check>
                            IsLive
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md={4} style={{ marginTop: "25px" }}>
                        <FormGroup check>
                          <Input
                            name='isInReview'
                            id="checkbox"
                            type="checkbox"
                            defaultChecked={validation.values.isInReview}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.isInReview}
                          />
                          <Label for="checkbox" style={{ marginTop: "1px" }} check>
                          Is InReview
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button
                        color="secondary"
                        className="btn btn-primary me-2 btn-sm "
                        type="reset"
                      >
                        Reset
                      </Button>
                      {formSumitLoading ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                          Loading
                        </button>
                      ) : (
                        <Button
                          color="primary"
                          className="btn btn-primary btn-sm "
                          type="submit"
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
AppVersion.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AppVersion
