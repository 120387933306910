import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Compressor from "compressorjs"
import { useFormik } from 'formik'
import * as Yup from "yup"
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import breadcrumbs from 'common/Breadcrumbs'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import uploadImage from '../../../../assets/images/uploadImage.png'
import Dropzone from 'react-dropzone'
import { toast } from 'react-hot-toast'
import useApi from 'common/ApiMiddlewere'
import { routes } from 'routes/path'
import ImagesCrops from './ImagesCrops'
import ImageCrop from './ImageCrop'

let imagePreviews = []
let images = ''
let urls = []
let base64Array = []
let Gender = ""
const ProductVerificationUpdate = () => {
    const { state } = useLocation()
    const { putApi, getApi } = useApi()
    document.title = ` ${state ? "Update Product" : "Create Product"} | ${localStorage.getItem("ShopName")}`
    const navigate = useNavigate()
    const [gender, setGender] = useState("Male")
    let { pathname } = useLocation()
    const [previewUrl, setPreviewUrl] = useState()
    const [colors, setColors] = useState([])
    const [categories, setCategories] = useState([])
    const [editProductData, setEditProductData] = useState([])
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [previewUrl1, setPreviewUrl1] = useState(uploadImage)
    const [previewUrl2, setPreviewUrl2] = useState(uploadImage)
    const [previewUrl3, setPreviewUrl3] = useState(uploadImage)
    const [previewUrl4, setPreviewUrl4] = useState(uploadImage)
    const [cropImage1, setCropImage1] = useState(uploadImage)
    const [cropImage2, setCropImage2] = useState(uploadImage)
    const [cropImage3, setCropImage3] = useState(uploadImage)
    const [cropImage4, setCropImage4] = useState(uploadImage)
    const [isUrl, setIsUrl] = useState(false)
    const [cropImage, setCropImage] = useState('')
    const [modal1, setModal1] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [imagesOndrops, setImagesOndrops] = useState(0)
    const [imagesPreviews1, setImagesPreviews] = useState([])
    const [verificationRemark, setVerificationRemark] = useState('')
    const [productVerificationModal, setProductVerificationModal] = useState(false)
    const [remarkError, setRemarkError] = useState(false)
    const [screeLoad, setscreeLoad] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [imageViewModal, setImageViewModal] = useState(false)
    const [isImage, setIsImage] = useState(false)
    const [viewImage, setViewImage] = useState('')

    useEffect(() => {
        getApi("api/Colors/list").then(
            ({ data, status }) => {
                if (status === 200) {
                    setColors(data?.colorsList)
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            }
        )

        getApi(`api/Categories/all`).then(({ data, status }) => {
            if (status === 200) {
                setCategories(data)
            }
        })

    }, []);

    const toggle1 = () => {
        setModal1(!modal1)
    }

    const toggle2 = () => {
        setModal2(!modal2)
    }

    const imageViewToggle = () => {
        setImageViewModal(!imageViewModal)
    }

    const productVerificationtoggle = () => {
        setProductVerificationModal(!productVerificationModal)
    }

    useEffect(() => {
        setIsUrl(false)
        setIsEdit(true)
        validation.handleReset()
        if (state) {
            Gender = ''
            getApi(`api/Product/details?id=${state.productId}`, {
                id: state.productId,
            }).then(({ data, status }) => {
                if (status == 200) {
                    setGender(data?.targetGender)
                    Gender = data?.targetGender
                    images = data?.imageUrls
                    setPreviewUrl1(data?.imageUrls?.split(",")[0] || '')
                    setPreviewUrl2(data?.imageUrls?.split(",")[1] || '')
                    setPreviewUrl3(data?.imageUrls?.split(",")[2] || '')
                    setPreviewUrl4(data?.imageUrls?.split(",")[3] || '')
                    setCropImage1(data?.imageUrls?.split(",")[0] || uploadImage)
                    setCropImage2(data?.imageUrls?.split(",")[1] || uploadImage)
                    setCropImage3(data?.imageUrls?.split(",")[2] || uploadImage)
                    setCropImage4(data?.imageUrls?.split(",")[3] || uploadImage)
                    setPreviewUrl(data?.mainImageUrl)
                    setCropImage(data?.mainImageUrl)
                    setEditProductData(data)
                    data?.imageUrls?.split(",").map((item, index) => {
                        const updatedPreviews = [...imagePreviews];
                        updatedPreviews[index] = {
                            isUrl: false,
                            url: item,
                            base64: "",
                        };
                        imagePreviews = updatedPreviews;
                    })
                    data?.imageUrls?.split(",").map((item, index) => {
                        imagesPreviews1.push(
                            {
                                isUrl: false,
                                url: item,
                                base64: "",
                            })
                        setscreeLoad(false)
                    })
                } else {
                    setscreeLoad(false)
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })
        }
    }, [state])

    // product validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            color: editProductData?.colorId || 0,
            title: editProductData?.title || '',
            description: editProductData?.description || '',
            rent: editProductData?.rent == 0 ? 0 : editProductData?.rent || 0,
            salePrice: editProductData?.salePrice == 0 ? 0 : editProductData?.salePrice || 0,
            profilePic: editProductData?.mainImageUrl || "",
            brandName: editProductData?.brandName || "",
            discount: editProductData?.discount || "",
            deposit: editProductData?.deposit || "",
            categoryId: editProductData?.categoryId || 0,
        },
        validationSchema: Yup.object({
            color: Yup.number().min(1, "The color is required"),
            title: Yup.string().required("The title is required"),
            description: Yup.string().required("The description is required"),
            rent: Yup.string().required("The rent is required"),
            salePrice: Yup.string().required("The sale price is required"),
            profilePic: Yup.string().required("The main Image is required"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            urls = []
            base64Array = []
            if (values) {
                imagesPreviews1?.map((item, index) => {
                    if (item?.url != '') {
                        urls.push(item.url)
                    }
                    if (item?.base64 != '') {
                        base64Array.push(item.base64)
                    }
                })
                const updateBody = {
                    id: state?.productId,
                    title: values.title,
                    categoryId: parseInt(values.categoryId),
                    brandName: values.brandName,
                    colorId: parseInt(values.color),
                    rent: parseInt(values.rent || 0),
                    salePrice: parseInt(values.salePrice || 0),
                    deposit: parseInt(values.deposit || 0),
                    discount: parseInt(values.discount || 0),
                    targetGender: gender,
                    description: values.description,
                    images: base64Array,
                    imageUrls: urls,
                    mainImage: isUrl ? cropImage.split(",")[1] : "",
                    ThumbnailUrl: isUrl ? cropImage.split(",")[1] : "",
                }
                putApi("api/Product/edit", updateBody).then(({ data, status }) => {
                    if (status === 200) {
                        productVerificationStatusChangeApi("Approved")
                        setformSumitLoading(false)
                        Gender = ''
                        setIsEdit(false)
                        setImagesPreviews([])
                    } else {
                        toast.error(JSON.parse(data)?.Message, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                    setformSumitLoading(false)
                })
            }
        }
    })

    // image select handler
    const handleDrop = acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            success: async compressedResult => {
                setIsUrl(true);
                setPreviewUrl(URL.createObjectURL(compressedResult))
                toggle1()
            },
        })
    }

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    // image 2 onDrop function
    const handleDrop1 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.4,
            success: async compressedResult => {
                setImagesOndrops(0)
                setPreviewUrl1(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    const handleRemoveImage = (index) => {
        if (index == 0) {
            setPreviewUrl1('');
            setCropImage1(uploadImage);
        } else if (index == 1) {
            setPreviewUrl2('');
            setCropImage2(uploadImage);
        } else if (index == 2) {
            setPreviewUrl3('');
            setCropImage3(uploadImage);
        } else if (index == 3) {
            setPreviewUrl4('');
            setCropImage4(uploadImage);
        }

        const updatedPreviews = [...imagesPreviews1];
        updatedPreviews[index] = {
            isUrl: true,
            url: '',
            base64: '',
        };
        imagePreviews = updatedPreviews;
        setImagesPreviews(updatedPreviews);
    }

    const handleViewImage = (previewUrl) => {
        setViewImage(previewUrl)
        imageViewToggle()
    }

    // image 3 onDrop function
    const handleDrop2 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.4,
            success: async compressedResult => {
                setImagesOndrops(1)
                setPreviewUrl2(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    // image 4 onDrop function
    const handleDrop3 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.4,
            success: async compressedResult => {
                setImagesOndrops(2)
                setPreviewUrl3(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    // image 5 onDrop function
    const handleDrop4 = () => async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.4,
            success: async compressedResult => {
                setImagesOndrops(3)
                setPreviewUrl4(URL.createObjectURL(compressedResult))
                toggle2()
            },
        })
    }

    const handleProductVerification = (VerificationStatus) => {
        if (VerificationStatus == "Approved") {
            productVerificationStatusChangeApi(VerificationStatus)
        } else {
            setProductVerificationModal(!productVerificationModal)
        }
    }

    const productVerificationStatusChangeApi = (VerificationStatus) => {
        if ((VerificationStatus == "Rejected" && verificationRemark != '') || (VerificationStatus == "Approved")) {
            putApi("api/Product/change-verification", {
                id: state?.productId,
                verificationStatus: VerificationStatus,
                remarks: verificationRemark,
            }).then(({ data, status }) => {
                if (status == 200) {
                    toast.success("successfully updated", {
                        style: {
                            fontSize: "15px",
                        },
                    })
                    navigate(routes.productVerification)
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })
            setRemarkError(false)
        } else {
            setRemarkError(true)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                    {screeLoad && (
                        <div className="loading-overlay is-active">
                            <span className="fas fa-spinner fa-3x fa-spin"></span>
                        </div>
                    )}

                    <Breadcrumbs
                        parent={breadcrumbs[pathname].parent}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                        parentLink={breadcrumbs[pathname].parentLink}
                    />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl="12">
                                    <Form
                                        className="needs-validation"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                        onReset={e => {
                                            e.preventDefault()
                                            validation.handleReset()
                                            return false
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Dropzone
                                                        onDrop={handleDrop}
                                                        onClick={handleDrop}
                                                        accept="image/*"
                                                        multiple={false}
                                                        id="profilePic"
                                                        name="profilePic"
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <>
                                                                <div className="d-flex flex-column align-items-center text-center p-2">
                                                                    <Label
                                                                        htmlFor="validationCustom03"
                                                                        className="required"
                                                                    >
                                                                        Main Image
                                                                    </Label>
                                                                    <>
                                                                        <div className="position-relative overflow-hidden product-image" style={{
                                                                            width: "180px",
                                                                            height: "215px",
                                                                            border: "1px solid #ced4da",
                                                                            cursor: "pointer",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderRadius: "3px",
                                                                        }}>
                                                                            <input {...getInputProps()} />
                                                                            <img
                                                                                className="img-fluid"
                                                                                style={{ height: "214px", width: "179px" }}
                                                                                src={cropImage ? cropImage : uploadImage}
                                                                                {...getRootProps()}
                                                                            />
                                                                            {cropImage &&
                                                                                <>
                                                                                    <div title='View' style={{ position: "absolute", top: "89%", left: "84%" }}>
                                                                                        <i className="bx bx-show-alt
" style={{ fontSize: "24px", color: "black", backgroundColor: "white", border: "1px solid #8080806b", paddingRight: "2px", paddingLeft: "2px" }} onClick={() => handleViewImage(cropImage)} />
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropzone>
                                                    <span style={{ justifyContent: 'center', display: "flex", alignItems: "center", textAlign: "center" }}>
                                                        {validation.touched.profilePic && validation.errors.profilePic ? (
                                                            <FormFeedback type="invalid" className="d-block">{validation.errors.profilePic}</FormFeedback>
                                                        ) : null}
                                                    </span>
                                                </FormGroup>


                                                <div className="mb-2 "
                                                    style={{ justifyContent: 'center', display: "flex", alignItems: "center" }}
                                                >
                                                    {isEdit && Gender != '' ?
                                                        <div className=" d-flex gap-2" style={{ marginTop: "7px" }}>
                                                            <input
                                                                type="radio"
                                                                value="Male"
                                                                id="customRadioInline1"
                                                                name="gender"
                                                                className="form-check-input"
                                                                defaultChecked={Gender == "Male"}
                                                                onChange={(e) => { setGender(e.target.value) }}
                                                            />
                                                            <Label
                                                                className="form-check-label font-size-13"
                                                                htmlFor="customRadioInline1"
                                                            >
                                                                Male
                                                            </Label>
                                                            <input
                                                                type="radio"
                                                                value="Female"
                                                                id="customRadioInline2"
                                                                name="gender"
                                                                className="form-check-input"
                                                                defaultChecked={Gender == "Female"}
                                                                onChange={(e) => { setGender(e.target.value) }}
                                                            />
                                                            <Label
                                                                className="form-check-label font-size-13"
                                                                htmlFor="customRadioInline2"
                                                            >
                                                                Female
                                                            </Label>
                                                            <input
                                                                type="radio"
                                                                value="Unisex"
                                                                id="customRadioInline3"
                                                                name="gender"
                                                                className="form-check-input"
                                                                defaultChecked={Gender == "Unisex"}
                                                                onChange={(e) => { setGender(e.target.value) }}
                                                            />
                                                            <Label
                                                                className="form-check-label font-size-13"
                                                                htmlFor="customRadioInline3"
                                                            >
                                                                Unisex
                                                            </Label>
                                                        </div>
                                                        :
                                                        <>
                                                            {!isEdit &&
                                                                <div className=" d-flex gap-2" style={{ marginTop: "7px" }}>
                                                                    <input
                                                                        type="radio"
                                                                        value="Male"
                                                                        id="customRadioInline1"
                                                                        name="gender"
                                                                        className="form-check-input"
                                                                        defaultChecked={gender == "Male"}
                                                                        onChange={(e) => { setGender(e.target.value) }}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label font-size-13"
                                                                        htmlFor="customRadioInline1"
                                                                    >
                                                                        Male
                                                                    </Label>
                                                                    <input
                                                                        type="radio"
                                                                        value="Female"
                                                                        id="customRadioInline2"
                                                                        name="gender"
                                                                        className="form-check-input"
                                                                        defaultChecked={gender == 'Female'}
                                                                        onChange={(e) => { setGender(e.target.value) }}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label font-size-13"
                                                                        htmlFor="customRadioInline2"
                                                                    >
                                                                        Female
                                                                    </Label>
                                                                    <input
                                                                        type="radio"
                                                                        value="Unisex"
                                                                        id="customRadioInline3"
                                                                        name="gender"
                                                                        className="form-check-input"
                                                                        defaultChecked={gender == "Unisex"}
                                                                        onChange={(e) => { setGender(e.target.value) }}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label font-size-13"
                                                                        htmlFor="customRadioInline3"
                                                                    >
                                                                        Unisex
                                                                    </Label>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div className="mb-2 "
                                                    style={{ justifyContent: 'center', display: "flex", alignItems: "center" }}
                                                >
                                                    <div className=" d-flex gap-3" style={{ marginTop: "7px" }}>
                                                        <FormGroup check>
                                                            <Input
                                                                id="checkbox1"
                                                                type="checkbox"
                                                                disabled
                                                                defaultChecked={editProductData?.forRent}
                                                            />
                                                            <Label for="checkbox1" style={{ marginTop: "1px" }} check>
                                                                Rent
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input
                                                                id="checkbox2"
                                                                type="checkbox"
                                                                disabled
                                                                defaultChecked={editProductData?.forSale}
                                                            />
                                                            <Label for="checkbox2" style={{ marginTop: "1px" }} check>
                                                                Sale
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="9">
                                                <Row>
                                                    <Col md="8">
                                                        <FormGroup className="mb-2">
                                                            <Label
                                                                htmlFor="validationCustom03"
                                                                className="required"
                                                            >
                                                                Title
                                                            </Label>
                                                            <Input
                                                                name="title"
                                                                placeholder="Title"
                                                                type="text"
                                                                className="form-control-sm"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.title || ""}
                                                                invalid={
                                                                    validation.touched.title &&
                                                                        validation.errors.title
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.title &&
                                                                validation.errors.title ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.title}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-2">
                                                            <Label
                                                                htmlFor="validationCustom03"
                                                                className=""
                                                            >
                                                                Brand Name
                                                            </Label>
                                                            <Input
                                                                name="brandName"
                                                                placeholder="Brand Name"
                                                                type="text"
                                                                className="form-control-sm"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.brandName}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-2">
                                                            <Label
                                                                htmlFor="validationCustom01"
                                                                className="required"
                                                            >
                                                                Category
                                                            </Label>
                                                            <Input
                                                                name="categoryId"
                                                                // disabled={state}
                                                                placeholder="Full Name"
                                                                type="select"
                                                                bsSize='sm'
                                                                className="form-control-sm"
                                                                id="validationCustom01"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.categoryId || ""}
                                                                invalid={
                                                                    validation.touched.categoryId &&
                                                                        validation.errors.categoryId
                                                                        ? true
                                                                        : false
                                                                }
                                                            >  {categories.map((item, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={item.id}
                                                                        disabled={!item.isLast}
                                                                        className={item.isLast ? '' : 'category-disabled'}
                                                                    >
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                            </Input>
                                                            {validation.touched.categoryId &&
                                                                validation.errors.categoryId ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.categoryId}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup className="mb-2">
                                                            <Label className="required" htmlFor="validationCustom01">Color</Label>
                                                            <Input
                                                                type="select"
                                                                bsSize="sm"
                                                                id="validationCustom01"
                                                                className="form-control-sm"
                                                                name="color"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.color}
                                                                invalid={
                                                                    validation.touched.color &&
                                                                        validation.errors.color
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                {colors.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.id}>
                                                                            {item.name}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </Input>
                                                            {validation.touched.color &&
                                                                validation.errors.color ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.color}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    {editProductData?.forRent ?
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label className="form-label required">
                                                                    Rent
                                                                </Label>
                                                                <Input
                                                                    name="rent"
                                                                    className="form-control-sm"
                                                                    placeholder="0"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    onKeyPress={handleKeyPress}
                                                                    value={validation.values.rent}
                                                                    invalid={
                                                                        validation.touched.rent &&
                                                                            validation.errors.rent
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.rent &&
                                                                    validation.errors.rent ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.rent}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        :
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label className="form-label required">
                                                                    Sale Price
                                                                </Label>
                                                                <Input
                                                                    name="salePrice"
                                                                    className="form-control-sm"
                                                                    placeholder="0"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    onKeyPress={handleKeyPress}
                                                                    value={validation.values.salePrice}
                                                                    invalid={
                                                                        validation.touched.salePrice &&
                                                                            validation.errors.salePrice
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.salePrice &&
                                                                    validation.errors.salePrice ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.salePrice}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    }
                                                    <Col md={2}>
                                                        <div className="mb-2">
                                                            <Label className="form-label">
                                                                Discount
                                                            </Label>
                                                            <Input
                                                                name="discount"
                                                                className="form-control-sm"
                                                                placeholder="0"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={validation.values.discount}
                                                                invalid={
                                                                    validation.touched.discount &&
                                                                        validation.errors.discount
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.discount &&
                                                                validation.errors.discount ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.discount}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="mb-2">
                                                            <Label className="form-label">
                                                                Desposit
                                                            </Label>
                                                            <Input
                                                                name="deposit"
                                                                className="form-control-sm"
                                                                placeholder="0"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                onKeyPress={handleKeyPress}
                                                                value={validation.values.deposit}
                                                                invalid={
                                                                    validation.touched.deposit &&
                                                                        validation.errors.deposit
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.deposit &&
                                                                validation.errors.deposit ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.deposit}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup className="mb-2">
                                                            <Label className="required" >Description</Label>
                                                            <Input
                                                                name="description"
                                                                rows="4"
                                                                placeholder="Type your remark here"
                                                                type="textarea"
                                                                className="form-control-sm"
                                                                value={validation.values.description || ""}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={
                                                                    validation.touched.description &&
                                                                        validation.errors.description
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                            </Input>
                                                            {validation.touched.description &&
                                                                validation.errors.description ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.description}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label
                                                        htmlFor=""
                                                        className=""
                                                    >
                                                        Images
                                                    </Label>
                                                    <div>
                                                        <div className="d-flex">
                                                            <FormGroup>
                                                                <Dropzone
                                                                    onDrop={handleDrop1()}
                                                                    onClick={handleDrop1()}
                                                                    accept="image/*"
                                                                    multiple={false}
                                                                    id="profilePic1"
                                                                    name="profilePic1"
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <>
                                                                            <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                                <input {...getInputProps()} />
                                                                                <div
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: "2px solid #0000004d",
                                                                                        height: "123px",
                                                                                        width: "110px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        borderRadius: "2%",
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        <img
                                                                                            style={{
                                                                                                cursor: "pointer", height: "121px",
                                                                                                width: "110px",
                                                                                            }}
                                                                                            src={cropImage1 || previewUrl1}
                                                                                            {...getRootProps()}
                                                                                        />
                                                                                    </>
                                                                                    {(previewUrl1) &&
                                                                                        <>
                                                                                            <div style={{ position: "absolute", top: "0", left: "83%" }}>
                                                                                                <i className="bx bx-x" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }}
                                                                                                    onClick={() => handleRemoveImage(0)} />
                                                                                            </div>
                                                                                            <div title='View' style={{ position: "absolute", top: "16%", left: "83%" }}>
                                                                                                <i className="bx bx-show-alt
" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }} onClick={() => handleViewImage(previewUrl1)} />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Dropzone>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Dropzone
                                                                    onDrop={handleDrop2()}
                                                                    onClick={handleDrop2()}
                                                                    accept="image/*"
                                                                    multiple={false}
                                                                    id="profilePic2"
                                                                    name="profilePic2"
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <>
                                                                            <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                                <input {...getInputProps()} />
                                                                                <div
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: "2px solid #0000004d",
                                                                                        height: "123px",
                                                                                        width: "110px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        borderRadius: "2%",
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        <img
                                                                                            style={{
                                                                                                cursor: "pointer", height: "121px",
                                                                                                width: "110px",
                                                                                            }}
                                                                                            src={cropImage2 || previewUrl2}
                                                                                            {...getRootProps()}
                                                                                        />
                                                                                    </>
                                                                                    {(previewUrl2) &&
                                                                                        <>
                                                                                            <div style={{ position: "absolute", top: "0", left: "83%" }}>
                                                                                                <i className="bx bx-x" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }}
                                                                                                    onClick={() => handleRemoveImage(1)} />
                                                                                            </div>
                                                                                            <div title='View' style={{ position: "absolute", top: "16%", left: "83%" }}>
                                                                                                <i className="bx bx-show-alt
" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }} onClick={() => handleViewImage(previewUrl2)} />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Dropzone>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Dropzone
                                                                    onDrop={handleDrop3()}
                                                                    onClick={handleDrop3()}
                                                                    accept="image/*"
                                                                    multiple={false}
                                                                    id="profilePic3"
                                                                    name="profilePic3"
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <>
                                                                            <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                                <input {...getInputProps()} />
                                                                                <div
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: "2px solid #0000004d",
                                                                                        height: "123px",
                                                                                        width: "110px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        borderRadius: "2%",
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        <img
                                                                                            style={{
                                                                                                cursor: "pointer", height: "121px",
                                                                                                width: "110px",
                                                                                            }}
                                                                                            src={cropImage3 || previewUrl3}
                                                                                            {...getRootProps()}
                                                                                        />
                                                                                    </>
                                                                                    {(previewUrl3) &&
                                                                                        <>
                                                                                            <div style={{ position: "absolute", top: "0", left: "83%" }}>
                                                                                                <i className="bx bx-x" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }}
                                                                                                    onClick={() => handleRemoveImage(2)} />
                                                                                            </div>
                                                                                            <div title='View' style={{ position: "absolute", top: "16%", left: "83%" }}>
                                                                                                <i className="bx bx-show-alt
" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }} onClick={() => handleViewImage(previewUrl3)} />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Dropzone>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Dropzone
                                                                    onDrop={handleDrop4()}
                                                                    onClick={handleDrop4()}
                                                                    accept="image/*"
                                                                    multiple={false}
                                                                    id="profilePic4"
                                                                    name="profilePic4"
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <>
                                                                            <div className="position-relative overflow-hidden product-image" style={{ marginRight: "10px" }}>
                                                                                <input {...getInputProps()} />
                                                                                <div
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        border: "2px solid #0000004d",
                                                                                        height: "123px",
                                                                                        width: "110px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        borderRadius: "2%",
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        <img
                                                                                            style={{
                                                                                                cursor: "pointer", height: "121px",
                                                                                                width: "110px",
                                                                                            }}
                                                                                            src={cropImage4 || previewUrl4}
                                                                                            {...getRootProps()}
                                                                                        />
                                                                                    </>
                                                                                    {(previewUrl4) &&
                                                                                        <>
                                                                                            <div style={{ position: "absolute", top: "0", left: "83%" }}>
                                                                                                <i className="bx bx-x" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }}
                                                                                                    onClick={() => handleRemoveImage(3)} />
                                                                                            </div>
                                                                                            <div title='View' style={{ position: "absolute", top: "16%", left: "83%" }}>
                                                                                                <i className="bx bx-show-alt
" style={{ fontSize: "17px", color: "black", backgroundColor: "white", border: "1px solid #8080806b" }} onClick={() => handleViewImage(previewUrl4)} />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Dropzone>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" className="text-end">
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary me-2"
                                                    type="reset"
                                                    onClick={() => {
                                                        navigate(routes.productVerification)
                                                    }}
                                                >
                                                    Cancel
                                                </Button>

                                                {/* <Button
                                                    color="green"
                                                    className="btn btn-approve me-2"
                                                    type="button"
                                                    onClick={() => {
                                                        handleProductVerification("Approved")
                                                    }}
                                                >
                                                     Save & Approve
                                                </Button> */}

                                                <>
                                                    {formSumitLoading ? (
                                                        <button type="button" className="btn btn-primary ">
                                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                            Loading
                                                        </button>
                                                    ) : (
                                                        <Button
                                                            color="green"
                                                            // className="btn btn-primary me-2"
                                                            className="btn btn-approve me-2"
                                                            type="submit"
                                                        >
                                                            Save & Approve
                                                        </Button>
                                                    )}
                                                </>
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-reject me-2"
                                                    type="button"
                                                    onClick={() => {
                                                        handleProductVerification("Rejected")
                                                    }}
                                                >
                                                    Reject
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                            <Modal
                                isOpen={modal1}
                                toggle={toggle1}
                                centered={true}
                                size="lg"
                                style={{ width: "600px" }}
                            >
                                <div className="modal-content">
                                    <ModalHeader toggle={toggle1} tag="h5">
                                        {"Crop Image"}
                                    </ModalHeader>
                                    <ModalBody style={{ height: "490px" }}>
                                        <ImageCrop
                                            image={previewUrl}
                                            setCropImage={setCropImage}
                                            toggle={toggle1}
                                            validation={validation}
                                            cropImage={cropImage}
                                        />
                                    </ModalBody>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={modal2}
                                toggle={toggle2}
                                centered={true}
                                size="lg"
                                style={{ width: "600px" }}
                            >
                                <div className="modal-content">
                                    <ModalHeader toggle={toggle2} tag="h5">
                                        {"Crop Image"}
                                    </ModalHeader>
                                    <ModalBody style={{ height: "490px" }}>
                                        <ImagesCrops
                                            image={imagesOndrops == 0 ? previewUrl1 : imagesOndrops == 1 ? previewUrl2 : imagesOndrops == 2 ? previewUrl3 : previewUrl4}
                                            imagesOndrops={imagesOndrops}
                                            setCropImage={imagesOndrops == 0 ? setPreviewUrl1 : imagesOndrops == 1 ? setPreviewUrl2 : imagesOndrops == 2 ? setPreviewUrl3 : setPreviewUrl4}
                                            setCropImages1={imagesOndrops == 0 ? setCropImage1 : imagesOndrops == 1 ? setCropImage2 : imagesOndrops == 2 ? setCropImage3 : setCropImage4}
                                            toggle={toggle2}
                                            images={images}
                                            imagesPreviews1={imagesPreviews1}
                                            setImagesPreviews={setImagesPreviews}
                                            setIsImage={setIsImage}
                                        />
                                    </ModalBody>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={productVerificationModal}
                                toggle={productVerificationtoggle}
                                centered={true}
                                size="small"
                                style={{ width: "450px" }}
                            >
                                <div className="modal-content">
                                    <ModalHeader toggle={productVerificationtoggle} tag="h5">
                                        Remark
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup className="mb-2">
                                            <Input
                                                required
                                                name="remark"
                                                placeholder="Type your remark here"
                                                rows="3"
                                                type="textarea"
                                                className="form-control-sm"
                                                onChange={(e) => setVerificationRemark(e.target.value)}
                                                value={verificationRemark}
                                                invalid={remarkError}
                                            />
                                            {remarkError ? (
                                                <FormFeedback type="invalid">
                                                    Remark is required
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                        <div className="text-end">
                                            <Button
                                                color="secondary"
                                                className="btn btn-primary me-2 btn-sm "
                                                type="button"
                                                onClick={() => setProductVerificationModal(false)}
                                            >
                                                Cancel
                                            </Button>
                                            <>
                                                <Button
                                                    color="primary"
                                                    className="btn btn-primary btn-sm "
                                                    type="button"
                                                    onClick={() => productVerificationStatusChangeApi("Rejected")}
                                                >
                                                    Submit
                                                </Button>
                                            </>
                                        </div>
                                    </ModalBody>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={imageViewModal}
                                toggle={imageViewToggle}
                                centered={true}
                                size="default"
                                style={{ width: "485px" }}
                            >
                                <div className="modal-content">
                                    <ModalHeader toggle={imageViewToggle} tag="h5">
                                        {"Image"}
                                    </ModalHeader>
                                    <ModalBody>
                                        <img
                                            style={{
                                                cursor: "pointer", height: "121px",
                                                width: "450px",
                                                height: "500px",
                                                position: "relative",
                                            }}
                                            src={viewImage}
                                        />
                                    </ModalBody>
                                </div>
                            </Modal>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProductVerificationUpdate