var CryptoJS = require("crypto-js")
import { v4 as uuidv4 } from "uuid"

//suDoLetMeInsuDoLetMeInsuDoLetMeI
//56798iyxrxtcjyvf

const encryptData = async ({ Token }, input) => {
  // const { token } = localStorage.getItem("authUser")
  //   ? JSON.parse(localStorage.getItem("authUser"))
  //   : ""

  const keyStr = Token?.substring(0, 32)
  const ivStr = Token?.substring(Token?.length - 16)
  const iv = CryptoJS.enc.Utf8.parse(ivStr)
  const key = CryptoJS.enc.Utf8.parse(keyStr)

  //   let key = uuidv4().replace("-", "")
  //   key = CryptoJS.enc.Utf8.parse(key)

  // Encrypt the string to an array of bytes.
  const encrypted = CryptoJS.AES.encrypt(input, key, {
    iv: iv,
  }).ciphertext
  return encrypted.toString(CryptoJS.enc.Base64)
}

const decryptData = async ({ Token }, input) => {
  // const { token } = localStorage.getItem("authUser")
  //   ? JSON.parse(localStorage.getItem("authUser"))
  //   : ""

  const keyStr = Token?.substring(0, 32)
  const ivStr = Token?.substring(Token?.length - 16)
  const iv = CryptoJS.enc.Utf8.parse(ivStr)
  const key = CryptoJS.enc.Utf8.parse(keyStr)

  input = CryptoJS.enc.Base64.parse(input)

  // Encrypt the string to an array of bytes.
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: input }, key, {
    iv: iv,
  }).toString(CryptoJS.enc.Utf8)

  return decrypted
}

export { encryptData, decryptData }
