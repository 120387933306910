// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import TableContainer from "../../../../components/Common/TableContainer"
import { useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    UncontrolledTooltip,
} from "reactstrap"
import DeleteModel from "common/deleteModel/deleteModel"

import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import Loader from "common/Loader"
import breadcrumbs from "../../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import ProductTable from "./ProductTable"

function Products() {
    let { pathname } = useLocation()
    const { deleteApi, postApi, putApi, getApi } = useApi()
    document.title = `Products | ${localStorage.getItem("ShopName")}`
    const [TotalRecords, setTotalRecords] = useState(0)
    const [categories, setCategories] = useState([])
    const [statusChange, setStatusChange] = useState('0')

    const navigate = useNavigate()

    // useStates

    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [screeLoad, setscreeLoad] = useState(true)
    const [searchText, setSearchText] = useState("")
    const [displayStart, setDisplayStart] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [categoryId, setCategoryId] = useState(0)


    useEffect(() => {
        getApi(`api/Categories/all`).then(({ data, status }) => {
            if (status === 200) {
                setCategories(data)
            }
            setscreeLoad(false)
        })
    }, [])


    // filterData for Product
    const filterData = textFilter => {
        setSearchText(textFilter)
    }

    // pagination
    const pagePerSize = (pageSize, pagePerIndex, serchValue, categoryId) => {
        setSearchText(serchValue == undefined ? "" : serchValue)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setRowsPerPage(pageSize)
        setCategoryId(categoryId)
    }

    // productlist Api
    const productlistApi = () => {
        setscreeLoad(true)
        setIsLoading(true)
        postApi("api/Product/list", {
            userId: 0,
            categoryId: categoryId,
            verificationStatus: statusChange,
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText,
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.productList)
                setTotalRecords(data.totalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })
    }

    useEffect(() => {
        productlistApi()
    }, [searchText, displayStart, rowsPerPage, categoryId, statusChange])

    // product delete handler
    const handleDelete = productId => {
        if (productId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this product permanently? ",
            }).then(response => {
                if (response == true) {
                    setscreeLoad(true)
                    deleteApi(`api/Product/delete?id=${productId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.id !== productId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                setscreeLoad(false)
                                toast.success("Product sucessfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                setscreeLoad(false)
                                ErrorModel.errormodel({
                                    title: "Verify Product",
                                    text: data,
                                })
                            }
                        }
                    )
                }
            })
        }
    }

    // product active handler
    const handleSwitch = (id, isActive, e) => {
        if (id) {
            putApi("api/Product/product-active-inactive", { id: id }).then(({ data, status }) => {
                e.preventDefault()
                if (status === 200) {
                    toast.success("Product sucessfully changed", {
                        style: {
                            fontSize: "15px",
                        },
                    })
                } else {
                    $("#isActive_" + id + "").prop("checked", isActive)
                    ErrorModel.errormodel({
                        title: "Verify Product",
                        text: data,
                    })
                }
            })
        }
    }

    // product edit Id handler
    const editProduct = productId => {
        if (productId) {
            navigate(routes.productUpdate, {
                state: {
                    productId,
                },
            })
        }
    }

    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "ProductId | UserId",
                accessor: "id",
                Cell: ({ row }) => (
                    <span>
                        {row.original.id} | {row.original.userId}
                    </span>
                ),
            },
            {
                Header: "Picture",
                accessor: "mainImageUrl",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                className=""
                                style={{ textAlign: 'center' }}
                                src={row.original.mainImageUrl}
                                height={40}
                                width={40}
                            />
                        </div>
                    )
                },
            },
            {
                Header: "Title",
                accessor: "title",
                Cell: ({ row }) => (
                    <span>
                        {row.original.title ? row.original.title : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Category",
                accessor: "categoryName",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.categoryName ? row.original.categoryName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Color",
                accessor: "colorName",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.colorName ? row.original.colorName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Brand Name",
                accessor: "brandName",
                width: "5%",
                Cell: ({ row }) => (
                    <span>{row.original.brandName ? row.original.brandName : "N/A"}</span>
                ),
            },
            {
                Header: "Product For",
                width: "5%",
                Cell: ({ row }) => (
                    <span>{row.original.forRent ? "Rent" : "Sale"}</span>
                ),
            },
            {
                Header: "Amount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div>
                            <i className="mdi mdi-currency-inr"></i>
                            {row?.original?.forRent ? row?.original?.rent?.toFixed(2) || "0.00" : row?.original?.salePrice?.toFixed(2) || "0.00"}
                        </div>
                    )
                },
            },
            {
                Header: "Depoeit",
                accessor: "deposit",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div>
                            <i className="mdi mdi-currency-inr"></i>
                            {row.original.deposit.toFixed(2)}
                        </div>
                    )
                },
            },
            {
                Header: "Date",
                accessor: "createdOn",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.createdOn.split("T")[0] == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original.createdOn, "dd/MM/yyyy")}
                    </span>
                ),
            },
            {
                Header: "Verification",
                accessor: "verificationStatus",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div>
                            <div style={{ color: row.original.verificationStatus == "Approved" ? "#5D9C59" : row.original.verificationStatus == "Rejected" ? "#F24C3D" : "#d5993d", fontWeight: "600" }}>
                                {row.original.verificationStatus}
                                {row.original.verificationStatus == "Rejected" &&
                                    <div className="mytooltip">
                                        <i
                                            className="fas fa-eye ml-2"
                                            style={{ fontSize: "14px", marginLeft: "5px", cursor: "pointer" }}
                                        ></i>
                                        <span className="mytext">{row?.original?.remarks}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: "Is Active",
                accessor: "isActive",
                width: "8%",
                Cell: ({ row }) => (
                    <FormGroup switch className="d-flex justify-content-center">
                        <Input
                            type="switch"
                            id={`isActive_${row.original.id}`}
                            // disabled={row.original.IsRetire == true}
                            defaultChecked={row.original.isActive}
                            onClick={e => {
                                handleSwitch(row.original.id, row.original.isActive, e)
                            }}
                        />
                    </FormGroup>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className="  fas fa-edit btn-edit"
                                    style={{ fontSize: "14px" }}
                                    id={`edittooltip${row.original.id}`}
                                    onClick={() => editProduct(row.original.id)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.id}`}
                                    onClick={() => handleDelete(row.original.id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )
    // redirect create product handler
    const handleUserClicks = () => {
        navigate(routes.productCreate)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screeLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <ProductTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCreateProduct={true}
                            handleUserClick={handleUserClicks}
                            customPageSize={10}
                            className="custom-header-css"
                            filterData={filterData}
                            pagePerSize={pagePerSize}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            categories={categories}
                            setStatusChange={setStatusChange}
                            statusChange={statusChange}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
Products.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Products