import Swal from 'sweetalert2'

const errormodel = ({ title, text }) => {
    Swal.fire({
        title,
        icon: 'warning',
        width: "450px",
        heightAuto: false,
        html: text,
        showCloseButton: true,
        showConfirmButton: false,

    })
}
const ErrorModel = {
    errormodel,
}

export default ErrorModel