export const routes = {
  index: "/",
  dashboard: "/dashboard",
  userList: "/user",
  userCreate: "/user/create",
  userUpdate: "/user/update",
  inquiries: "/inquiries",
  size: "/size",
  color: "/color",
  timeslot: "/timeslot",
  plan: "/plan",
  available: "/available",
  demo: "/demo",
  plans: "/plans",
  productList: "/products",
  bookingList: "/booking",
  createbooking: "/create-booking",
  updatebooking: "/update-booking",
  reportBooking: "/report-booking",
  delivery: "/delivery",
  return: "/return",
  feedback: "/feedback",
  setting: "/setting",
  invoice: "/invoice",
  subscription: "/subscription",
  categories: "/categories",
  profile: "/user-profile",
  viewBooking: "/view-booking",
  importProduct: "/import-product",
  cartlist: "/cart",
  productHistory: "/product-history",
  salesman: "/salesman-report",
  salesReport: "/sales-report",
  productVerification: "/product-verification",
  productVerificationUpdate: "/productVerification-update",
  uploadImage: "/upload-image",
  advertisement: "/advertisement",
  popularCategories: "/popular-categories",
  productCreate: "/product-create",
  productUpdate: "/product-update",
  appVersion: "/app-version",
  dbBackup: "/db-backup",
  notification: "/push-notification",
}
