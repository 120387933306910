import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Dropzone from "react-dropzone"
import uploadImage from "../../../../src/assets/images/uploadImage.png"
import { GoogleApiWrapper } from "google-maps-react"
import useApi from "common/ApiMiddlewere"
import Compressor from "compressorjs"
import toast from "react-hot-toast"
import { NotificationType } from "_mock/Static_data"

function Notification() {
    document.title = `Notification | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const { postApi } = useApi()
    const [previewUrl, setPreviewUrl] = useState('')
    const [isLoading, setisLoading] = useState(false)

    // Apptitle Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
            description: '',
            categoryIds: '',
            male: false,
            female: false,
            imageURL: '',
            latitude: '',
            longitude: '',
            notification: 0,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("The title is required"),
            description: Yup.string().required("The description is required"),
            // categoryIds: Yup.string().required("The categoryIds is required"),
        }),
        onSubmit: values => {
            setisLoading(true)
            if (values) {
                postApi("api/Notification/send-general-notification", {
                    title: values.title || '',
                    description: values.description || '',
                    imageUrl: values.imageURL.split(",")[1] || '',
                    messageObj: values.categoryIds || '',
                    rediusKm: parseFloat(values.rediusKm) || 0,
                    latitude: values.latitude || '',
                    longitude: values.longitude || '',
                    gender: values.male ? true : values.female ? false : null,
                    notificationType: values.notification,
                }).then(({ data, status }) => {
                    setisLoading(false)
                    if (status == 200) {
                        toast.success("successfully send", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        // validation.handleReset()
                        // setPreviewUrl('')
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                })
            }
        },
    })

    // image url convert toBase64 handle
    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })

    // image select handle
    const handleDrop = async acceptedFiles => {
        const selectedFile = acceptedFiles[0]
        new Compressor(selectedFile, {
            quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            success: async compressedResult => {
                setPreviewUrl(URL.createObjectURL(compressedResult))
                validation.setFieldValue("imageURL", await toBase64(compressedResult))
            },
        })
    }

    useEffect(() => {
        const mapOptions = {
            center: new google.maps.LatLng(21.2310051, 72.901111),
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        };
        const map = new google.maps.Map(document.getElementById('lat-lng-Map'), mapOptions);
        google.maps.event.addListener(map, 'click', function (e) {
            validation.setFieldValue("latitude", String(e.latLng.lat()).substring(0, 10))
            validation.setFieldValue("longitude", String(e.latLng.lng()).substring(0, 10))
        });
    }, [window.onload, window.pathname])

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode === 44 || charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };



    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <Form
                            className="needs-validation"
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                setPreviewUrl('')
                                return false
                            }}
                        >
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="d-flex flex-column align-items-center text-center p-2 py-0">
                                        <Dropzone
                                            onDrop={handleDrop}
                                            onClick={handleDrop}
                                            accept="image/*"
                                            multiple={false}
                                            id="imageURL"
                                            name="imageURL"
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <>
                                                    <div className="d-flex flex-column align-items-center text-center p-2 py-0">
                                                        {previewUrl ? (
                                                            <>
                                                                <input {...getInputProps()} />
                                                                <img
                                                                    className="pe-auto"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        border: "1px solid #ced4da",
                                                                        borderRadius: "3px",
                                                                    }}
                                                                    width="220px"
                                                                    height="200px"
                                                                    src={previewUrl}
                                                                    {...getRootProps()}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <input {...getInputProps()} />
                                                                <img
                                                                    className=""
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        border: "1px solid #ced4da",
                                                                        borderRadius: "3px",
                                                                    }}
                                                                    width="220px"
                                                                    height="200px"
                                                                    src={uploadImage}
                                                                    {...getRootProps()}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Dropzone>
                                    </FormGroup>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup className="mb-2">
                                                <Label
                                                    htmlFor="validationCustom01"
                                                    className="required"
                                                >
                                                    Notification
                                                </Label>
                                                <Input
                                                    type="select"
                                                    bsSize="sm"
                                                    // id="validationCustom01"
                                                    className="form-control-sm"
                                                    name="notification"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.notification}
                                                    invalid={
                                                        validation.touched.notification &&
                                                            validation.errors.notification
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {NotificationType.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.Key}>
                                                                {item.DisplayName}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                                {validation.touched.notification &&
                                                    validation.errors.notification ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.notification}
                                                    </FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md={8}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03" className="required">
                                                    Title
                                                </Label>
                                                <Input
                                                    name="title"
                                                    placeholder="Title"
                                                    type="text"
                                                    className="form-control-sm"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.title || ""}
                                                    invalid={
                                                        validation.touched.title && validation.errors.title
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.title && validation.errors.title ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.title}
                                                    </FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03" className="required">
                                                Description
                                            </Label>
                                            <Input
                                                name="description"
                                                placeholder="Description"
                                                type="textarea"
                                                rows={3}
                                                className="form-control-sm"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.description || ""}
                                                invalid={
                                                    validation.touched.description && validation.errors.description
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.description && validation.errors.description ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.description}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03" className="">
                                                    Catagoy Ids
                                                </Label>
                                                <Input
                                                    name="categoryIds"
                                                    placeholder="CatagoyIds"
                                                    type="text"
                                                    // onKeyPress={handleKeyPress}
                                                    className="form-control-sm"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.categoryIds || ""}
                                                    invalid={
                                                        validation.touched.categoryIds && validation.errors.categoryIds
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.categoryIds && validation.errors.categoryIds ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.categoryIds}
                                                    </FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03" className="">
                                                    Radius KM
                                                </Label>
                                                <Input
                                                    name="kilometer"
                                                    placeholder="Radius KM"
                                                    type="text"
                                                    onKeyPress={handleKeyPress}
                                                    className="form-control-sm"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.kilometer || ""}
                                                    invalid={
                                                        validation.touched.kilometer && validation.errors.kilometer
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.kilometer && validation.errors.kilometer ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.kilometer}
                                                    </FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <Label htmlFor="validationCustom03" className="">
                                                Gender
                                            </Label>
                                            <div style={{ display: "flex", gap: "20px", alignContent: "start", justifyContent: "start" }}>
                                                <FormGroup check>
                                                    <Input
                                                        name='male'
                                                        id="male_checkbox"
                                                        type="checkbox"
                                                        checked={validation.values.male}
                                                        onChange={(e) => {
                                                            validation.setFieldValue("male", e.target.checked);
                                                            validation.setFieldValue("female", false);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                    // value={validation.values.male}
                                                    />
                                                    <Label for="male_checkbox" style={{ marginTop: "1px" }} check>
                                                        Male
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        name='female'
                                                        id="female_checkbox"
                                                        type="checkbox"
                                                        defaultChecked={validation.values.female}
                                                        checked={validation.values.female}
                                                        onChange={(e) => {
                                                            validation.setFieldValue("female", e.target.checked);
                                                            validation.setFieldValue("male", false);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                    />
                                                    <Label for="female_checkbox" style={{ marginTop: "1px" }} check>
                                                        Female
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={7} className="mt-3">
                                    <div id="lat-lng-Map" style={{ height: "265px", width: "695px" }} />
                                </Col>
                                <Col md={3}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03" className="">
                                            Latitude
                                        </Label>
                                        <Input
                                            name="latitude"
                                            placeholder="Latitude"
                                            type="text"
                                            disabled
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.latitude || ""}
                                            invalid={
                                                validation.touched.latitude && validation.errors.latitude
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.latitude && validation.errors.latitude ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.latitude}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03" className="">
                                            Longitude
                                        </Label>
                                        <Input
                                            name="longitude"
                                            placeholder="Longitude"
                                            disabled
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.longitude || ""}
                                            invalid={
                                                validation.touched.longitude && validation.errors.longitude
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.longitude && validation.errors.longitude ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.longitude}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="text-end ">
                                    <Button
                                        color="secondary"
                                        className="btn btn-primary me-2"
                                        type="reset"
                                    >
                                        Reset
                                    </Button>
                                    {isLoading ? (
                                        <button type="button" className="btn btn-primary">
                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                            Loading
                                        </button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            className="btn btn-primary"
                                            type="submit"
                                        >
                                            Send  Notification
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
Notification.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

// export default Notification
// export default GroomWellProfile
export default GoogleApiWrapper({
    apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    v: "3",
})(Notification)